.why .swiper-pagination {
  position: static;
  margin-top: 20px;
  text-align: center;
}

/*active pagination*/
.why .swiper-pagination .swiper-pagination-bullet-active {
  @apply border-emerald-100
}

.why .swiper-pagination .swiper-pagination-bullet-active span {
  @apply bg-emerald-500 ;
  
}
