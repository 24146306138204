.swiper-pagination {
  position: static;
  margin-top: 20px;
  text-align: inherit;
}

/*active pagination*/
.swiper-pagination .swiper-pagination-bullet-active {
  border-color: #ccc;
}

.swiper-pagination .swiper-pagination-bullet-active span {
  background-color: #000 ;
  
}
