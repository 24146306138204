@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin.ttf');
}

@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand.ttf');
}

@font-face {
  font-family: 'About';
  src: url('../fonts/font-about.ttf');
}

html {
  /* scroll-behavior: smooth; */
}

.font-about {
  font-family: 'About', 'Quicksand', sans-serif;
}

body {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  /* color: #777; */
}

.text-icon {
  color: #f1a73c;
}

.h-modal {
  height: calc(100% - 2rem);
}

.text-bg-transparent {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1440px;
}

#logo {
  width: 176px;
}

.container-p {
  padding: 0 1rem;
}

.stuck {
  top: 0;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.sticky-jump .stuck {
  animation: stuckMoveDown 0.6s;
}

.header-wrapper:not(.stuck) .logo img {
  transition: max-height 0.5s;
}

.header-wrapper.stuck,
.header-shadow .header-wrapper,
.layout-shadow #wrapper {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
}

.dropdown-drawer {
  position: fixed;
  left: -9999px;
  opacity: 0;
  transform: translateX(-10px);
}

.active-dropdown {
  position: inherit;
  left: auto;
  opacity: 1;
  transform: translateX(0);
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  right: 0;
  bottom: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.bg-fill {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
}

@keyframes stuckMoveDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes stuckMoveUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes stuckFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-10%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.button-bottom:hover {
  animation: bounce 0.8s infinite;
}

.flickity-page-dots {
  position: absolute;
  bottom: 15px !important;
  left: 20% !important;
  right: 20% !important;
  padding: 0 !important;
  margin: 0;
  list-style: none;
  text-align: center !important;
  line-height: 1;
  width: auto !important;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 5px !important;
  border: 3px solid #111;
  border-radius: 50%;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.3s;
}

.flickity-page-dots .dot {
  opacity: 0.2;
  width: 13px !important;
  height: 13px !important;
  border: 3px solid #fff;
  background-color: transparent !important;
}

.flickity-page-dots .dot:hover {
  opacity: 0.7;
}

.flickity-page-dots .dot.is-selected {
  background-color: #fff !important;
  opacity: 1 !important;
}

.flickity-page-dots .dot {
  background-color: #111;
}

.flickity-page-dots .dot {
  border-color: #fff;
}

.flickity-page-dots .dot.is-selected {
  background-color: #fff;
}

.drawer-search {
  transform: translate(-50%, 50px);
}

.drawer-search.open {
  visibility: visible;
  position: fixed;
  top: 0;
  display: block;
  opacity: 1;
  transform: translate(-50%);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 21;
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  opacity: 0;
  top: 0;
}

[dir='rtl'] .overlay {
  right: 0;
}

:not([dir='rtl']) .overlay {
  left: 0;
}

.overlay {
  transition: 0.35s ease-in-out;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
}

.grid-p {
  display: grid;
  grid-template-rows: minmax(20px, 1fr);
  grid-template-columns: minmax(20px, 1fr);
}

.container-caption {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
}

.container-caption::before {
  -webkit-filter: blur(10px) saturate(2);
}

/* Animation loading */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #222;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@media only screen and (min-width: 768px) {
  .slideshow .swiper-wrapper {
    justify-content: center;
  }
}

.slideshow .swiper-button-disabled {
  background-color: #ccc;
}

.slideshow .swiper-button-disabled:hover {
  background-color: #ccc;
  color: #000;
  cursor: not-allowed;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* End animation loading */
